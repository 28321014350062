<template>
    <xy-group v-bind="$attrs" :label-for="$attrs.id" :class="`mb-1 ${required}`">
      <ValidationProvider v-bind="$attrs" :name="$attrs.label" #default="{ errors }">
        <b-form-textarea
            :id="$attrs.id"
            size="sm"
            v-bind="$attrs"
            v-on="$listeners"
            :value="val"
            :disabled="disabled || $attrs.disabled"
            @input="$emit('update:val',$event)"
            :state="errors[0] ? false : null"
        />
        <small class="text-danger">{{ errors[0] }}</small>
      </ValidationProvider>
    </xy-group>
</template>

<script>
import XyGroup from './XyGroup'
export default {
  components: { XyGroup },
  props:{
    val: {
      type: null
    },
  },
  computed: {
    required() {
      return this.$attrs.rules ? this.$attrs.rules.includes('required') ? 'required' : '' : ''
    }
  },
  inject: {
    disabled:{
      from:'_disabled',
      default:false
    }
  },
};
</script>

<style scoped>

</style>
