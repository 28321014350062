<template>
  <xy-group v-bind="$attrs" :label-for="$attrs.id" :class="`mb-1 ${required}`" v-if="$attrs.label">
    <ValidationProvider v-bind="$attrs" :name="$attrs.label" #default="{ errors }">
      <v-select
          :id="$attrs.id"
          v-bind="$attrs"
          v-on="$listeners"
          :label="$attrs.select_label || 'label'"
          :options="$attrs.options || getCodeOptions"
          :clearable="_clearable"
          :value="val"
          @input="$emit('update:val',$event)"
          class="select-size-sm"
          :reduce="$attrs.reduce || (item=>item.value) "
          :disabled="disabled || $attrs.disabled"
      >
      </v-select>
      <small class="text-danger">{{ errors[0] }}</small>
    </ValidationProvider>
  </xy-group>
  <ValidationProvider v-bind="$attrs" #default="{ errors }" v-else>
    <v-select
        :id="$attrs.id"
        v-bind="$attrs"
        v-on="$listeners"
        :label="$attrs.select_label || 'label'"
        :options="$attrs.options || getCodeOptions"
        :clearable="_clearable"
        :value="val"
        @input="$emit('update:val',$event)"
        class="select-size-sm"
        :disabled="disabled || $attrs.disabled"
        :reduce="$attrs.reduce || (item=>item.value) "
    >
    </v-select>
    <small class="text-danger">{{ errors[0] }}</small>
  </ValidationProvider>
</template>

<script>
import { getCodeOptions } from '@core/utils/filter'
import XyGroup from './XyGroup'

export default {
  components: { XyGroup },
  props: {
    val: {
      type: null
    },
    optionsType: {
      type: String
    }
  },
  computed: {
    required() {
      return this.$attrs.rules ? this.$attrs.rules.includes('required') ? 'required' : '' : ''
    },
    getCodeOptions() {
      return getCodeOptions(this.optionsType)
    },
    _clearable() {
      return this.$attrs.clearable === undefined ? true : this.$attrs.clearable
    }
  },
  inject: {
    disabled:{
      from:'_disabled',
      default:false
    }
  },
  created() {
  }
}
</script>

<style scoped>

</style>
