<template>
  <b-button
      v-bind="$attrs"
      v-on="$listeners"
      :disabled="disabled || $attrs.disabled"
  >
    <slot/>
  </b-button>
</template>

<script>
export default {
  name: 'XyButton',
  inject: {
    disabled: {
      from: '_disabled',
      default: false
    }
  },
}
</script>

<style scoped>

</style>
