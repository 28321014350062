<template>
  <xy-group v-bind="$attrs" :label-for="$attrs.id" :class="`mb-1 ${required}`">
    <ValidationProvider v-bind="$attrs" :name="$attrs.label" #default="{ errors }">
      <flat-pickr
          :id="$attrs.id"
          :value="innerValue"
          @input="onInput"
          v-bind="$attrs"
          v-on="$listeners"
          :disabled="disabled || $attrs.disabled"
          class="form-control"
      />
      <small class="text-danger">{{ errors[0] }}</small>
    </ValidationProvider>
  </xy-group>
</template>

<script>

import XyGroup from './XyGroup'
import { second, toDate, toTime } from '@core/utils/dayjs/utils'

export default {
  components: { XyGroup },
  props: {
    val: {
      type: null
    },
    format: {
      type: String,
      default: 'time'
    }
  },
  inject: {
    disabled: {
      from: '_disabled',
      default: false
    }
  },
  computed: {
    required() {
      return this.$attrs.rules ? this.$attrs.rules.includes('required') ? 'required' : '' : ''
    },
    innerValue() {
      return (this.format === 'time' ? toTime(this.val) : toDate(this.val))
    }
  },
  methods: {
    onInput(value) {
      this.$emit('update:val', second(value))
    }
  },
}
</script>

<style scoped>

</style>
