import { render, staticRenderFns } from "./XyForm.vue?vue&type=template&id=72373494&scoped=true&"
import script from "./XyForm.vue?vue&type=script&lang=js&"
export * from "./XyForm.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "72373494",
  null
  
)

export default component.exports