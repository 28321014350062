<template>
  <component  :is="currentComponent" v-bind="propData.attrs" v-on="propData.on" :val.sync="innerValue"></component>
</template>

<script>
import XyInput from '@/views/components/xy/XyInput'
import XySelect from '@/views/components/xy/XySelect'
import XyDatePicker from '@/views/components/xy/XyDatePicker'
import XyTextarea from '@/views/components/xy/XyTextarea'
import { kebabize } from '@core/utils/filter'


export default {
  components: {
    XyTextarea,
    XyDatePicker,
    XySelect,
    XyInput
  },

  props: {
    value: {
      type: null
    },
    propData: {
      type: Object
    },
  },
  data: () => ({
    innerValue: '',
  }),
  computed: {
    currentComponent: function() {
      return 'xy-' + kebabize(this.propData.type);
    }
  },
  watch: {
    innerValue(newVal) {
      this.$emit('input', newVal)
    },
    value(newVal) {
      this.innerValue = newVal
    },
  },
  created() {
    if (this.value) {
      this.innerValue = this.value
    }
  }
}


</script>

<style scoped>

</style>
