import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {getCodeOptions, toDate} from "@core/utils/filter";
import axios from "@/libs/axios";

export default function stockUseList(config) {
  // Use toast
  const toast = useToast()

  const refListTable = ref(null)

  // Table Handlers
  let tableColumns = [
    { key: 'stock_id', label: 'ID', sortable: true },
    { key: 'warehouse_name', label: '仓库', sortable: true },
    { key: 'purchase_team', label: '销售权', sortable: true },
    { key: 'company_id', label: '货主', sortable: true },
    { key: 'name', label: '商品', sortable: true },
    { key: 'code', label: '69码'},
    { key: 'production_date', label: '生产日期'},
    { key: 'is_gift', label: '是否赠品', sortable: true },
    { key: 'batch_no', label: '产品批次' },
    { key: 'qty', label: '数量', sortable: true },
    { key: 'outboundTrueQty', label: '待出库数量'},
    { key: 'cost', label: '成本', sortable: true },
    { key: 'location_fullname', label: '仓位', sortable: true },
    { key: 'status', label: '状态', sortable: true },
  ]

  const start = ref(1)
  const limit = ref(10)
  const limitOptions = [10, 25, 50, 100]
  const listTotals = ref(0)
  const searchKey = ref('')
  const orderBy = ref('batch_no desc,stock_id')
  const isSortDirDesc = ref(true)
  const warehouseIdVal = ref('')
  const productIdVal = ref('')
  const isSearch = ref(true)
  const month = ref('')

  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
    return {
      from: limit.value * (start.value - 1) + (localItemsCount ? 1 : 0),
      to: limit.value * (start.value - 1) + localItemsCount,
      of: listTotals.value,
    }
  })

  const refetchData = () => {
	  refListTable.value.refresh()
  }

  watch([start, limit,isSearch], () => {
    refetchData()
  })

  const searchList = (ctx, callback) => {
    if (config.companyId==0){
      config.companyId=null
    }
    store
      .dispatch('stock/search', {
        search_key: searchKey.value,
        start: start.value,
        limit: limit.value,
        order_by: orderBy.value,
        order_desc: isSortDirDesc.value === true ? 'desc':'',
        warehouseid: config.warehouseid,
        companyId:config.companyId,
        basicStoreId: config.basicStoreId,
        purchaseTeamUserId:config.purchaseTeamUserId,
        purchaseTeamStoreId:config.purchaseTeamStoreId,
      })
      .then(response => {
        const data = response.data.data
        const list = data.ext.list

        listTotals.value = data.ext.totals

        for(let i=0;i<list.length;i++){
          let stock = list[i]
          if ( list[i].ext.sumqty != null){
            list[i].sumqty = list[i].ext.sumqty
            list[i].saleqty = list[i].ext.saleqty

          }
          //填充锁库数据
          let stocklockList = []
          let stocklock = stock.ext.stocklocklist
          stocklockList.push({"label":"请选择锁库","stock_id":stock.id,"stocklock_id":0})
          if(stocklock.length > 0){
            for(let j=0;j<stocklock.length;j++){
              stocklockList.push({"label":stocklock[j].qty_free+"个"+toDate(stocklock[j].starttime)+"至"+toDate(stocklock[j].endtime),"stock_id":stock.id,"stocklock_id":stocklock[j].stocklock_id})
            }
          }
          list[i].temp1 = stocklockList
          list[i].temp1SL = {"label":"请选择锁库","stock_id":stock.id,"stocklock_id":0}

          //填充成本数据
          let costList = []
          let cost = stock.ext.costlist
          let defaultcost = {}
          if(cost.length == 0){
            defaultcost = {"label":"无成本数据","stock_id":stock.id,"purchaseorderitem_id":0}
          }
          else if(cost.length > 1){
            defaultcost = {"label":"请选择成本","stock_id":stock.id,"purchaseorderitem_id":0}
          }
          costList.push(defaultcost)

          if(cost.length > 0){
            for(let j=0;j<cost.length;j++){
              if(cost.length == 1){
                if(j == 0) {
                  defaultcost = {
                    "label": parseFloat(cost[0].product_cost_tax),
                    "stock_id": stock.id,
                    "purchaseorderitem_id": cost[0].item_id
                  }
                }
              }else{
                if(parseFloat(cost[j].product_cost_tax) === stock.cost){
                  defaultcost = {
                    "label": parseFloat(cost[j].product_cost_tax),
                    "stock_id": stock.id,
                    "purchaseorderitem_id": cost[j].item_id
                  }
                }
              }
              costList.push({"label":parseFloat(cost[j].product_cost_tax),"stock_id":stock.id,"purchaseorderitem_id":cost[j].item_id})
            }
          }
          list[i].temp2 = costList
          list[i].temp2SL = defaultcost
        }
        callback(list)
      })
      .catch((e) => {
        toast({
          component: ToastificationContent,
          props: {
            title: '库存列表获取错误',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const searchListAllInfo = (ctx, callback) => {
    if (config.companyId==0){
      config.companyId=null
    }
    store
      .dispatch('stock/searchAllInfo', {
        search_key: searchKey.value.replace(/'/g,""),
        start: start.value,
        limit: limit.value,
        order_by: orderBy.value,
        order_desc: isSortDirDesc.value === true ? 'desc':'',
        warehouseid: config.warehouseid,
        companyId:config.companyId,
        basicStoreId: config.basicStoreId,
        inbound_item_id: config.inbound_item_id,
        exclude_ids: config.exclude_ids,
        purchaseTeamUserId:config.purchaseTeamUserId,
        purchaseTeamStoreId:config.purchaseTeamStoreId,
        month:month.value
      })
      .then(response => {
        const data = response.data.data
        const list = data.ext.list
        listTotals.value = data.ext.totals

        for(let i=0;i<list.length;i++){
          let stock = list[i]

          //填充锁库数据
          let stocklockList = []
          let stocklock = stock.ext.stocklocklist
          stocklockList.push({"label":"请选择锁库","stock_id":stock.id,"stocklock_id":0})
          if(stocklock.length > 0){
            for(let j=0;j<stocklock.length;j++){
              stocklockList.push({"label":stocklock[j].qty_free+"个"+toDate(stocklock[j].starttime)+"至"+toDate(stocklock[j].endtime),"stock_id":stock.id,"stocklock_id":stocklock[j].stocklock_id})
            }
          }
          list[i].temp1 = stocklockList
          list[i].temp1SL = {"label":"请选择锁库","stock_id":stock.id,"stocklock_id":0}

          //填充成本数据
          let costList = []
          let cost = stock.ext.costlist
          let defaultcost = {}
          if(cost.length == 0){
            defaultcost = {"label":"无成本数据","stock_id":stock.id,"purchaseorderitem_id":0}
          }
          else if(cost.length > 1){
            defaultcost = {"label":"请选择成本","stock_id":stock.id,"purchaseorderitem_id":0}
          }
          costList.push(defaultcost)

          if(cost.length > 0){
            for(let j=0;j<cost.length;j++){
              if(cost.length == 1){
                if(j == 0) {
                  defaultcost = {
                    "label": parseFloat(cost[0].product_cost_tax),
                    "stock_id": stock.id,
                    "purchaseorderitem_id": cost[0].item_id
                  }
                }
              }else{
                if(parseFloat(cost[j].product_cost_tax) === stock.cost){
                  defaultcost = {
                    "label": parseFloat(cost[j].product_cost_tax),
                    "stock_id": stock.id,
                    "purchaseorderitem_id": cost[j].item_id
                  }
                }
              }
              costList.push({"label":parseFloat(cost[j].product_cost_tax),"stock_id":stock.id,"purchaseorderitem_id":cost[j].item_id})
            }
          }
          list[i].temp2 = costList
          list[i].temp2SL = defaultcost
        }
        callback(list)
      })
      .catch((e) => {
        toast({
          component: ToastificationContent,
          props: {
            title: '库存列表获取错误',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const searchListByWhIdAndPid = (ctx, callback) => {
    axios
        .get('/api/stock/findStockByWhIdAndPId', {params: {
          search_key: searchKey.value,
          start: start.value,
          limit: limit.value,
          order_by: orderBy.value,
          order_desc: isSortDirDesc.value === true ? 'desc':'',
          warehouse_id: warehouseIdVal.value,
          product_id: productIdVal.value,
          purchaseTeamUserId:config.purchaseTeamUserId,
        }})
        .then(response => {
          const data = response.data.data

          const list = data.ext.list

          listTotals.value = data.ext.totals



          callback(list)
        })
        .catch((e) => {
          toast({
            component: ToastificationContent,
            props: {
              title: '库存列表获取错误',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveStockTypeCode = value => {
    const val = String(value)
    if (val === '1') return {label: '渠道客户'}
    if (val === '2') return {label: '集团公司'}
    if (val === '3') return {label: '供应商'}
    if (val === '4') return {label: '仓库方'}
    if (val === '5') return {label: '物流方'}
    return {}
  }

  const resolveStateCode = value => {
    const val = String(value)
    if (val === '0') return {label: '禁用', color: 'secondary'}
    if (val === '1') return {label: '启用', color: 'success'}
    return {}
  }

  return {
    searchList,
    searchListAllInfo,
    searchListByWhIdAndPid,
    tableColumns,
    limit,
    start,
    listTotals: listTotals,
    isSearch,
    dataMeta,
    limitOptions,
    searchKey,
    month,
    orderBy,
    isSortDirDesc,
    refListTable,
    warehouseIdVal,
    productIdVal,

    resolveStockTypeCode,
    resolveStateCode,
    refetchData,

    // Extra Filters
  }
}
