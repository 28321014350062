import axios from '@axios'
import qs from 'qs'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    search(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/stocklock/search', {params: params})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    view(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/stocklock/view', {params: params})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    edit(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .get('/api/stocklock/edit', {params: params})
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    save(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/stocklock/save', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    state(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/stocklock/state', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },

    checkStock(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/stocklock/checkStock', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },

    submitcheck(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/stocklock/submitcheck', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },

    cancelapply(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/stocklock/cancelapply', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },

    getfreestock(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/stocklock/getfreestock', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },

    getstocklockinfo(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/stocklock/getstocklockinfo', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },

    freestock(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/stocklock/freestock', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },

    checkmax(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/stock/getStock', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },

    applysucess(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/stocklock/applysucess', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },

    checkfaild(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/stocklock/checkfaild', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },

    getaddfreestock(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/stocklock/getaddfreestock', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },

    addlockstock(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/stocklock/addlockstock', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },

  },
}
