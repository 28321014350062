<template>
  <span>
    <b-popover
        ref="popover"
        :target="uuid"
        triggers="click"
        :show.sync="popoverShow"
        placement="top"
        container="my-container"
    >
      <span>
        <b-form-group>
          <feather-icon
              icon="InfoIcon"
              size="15"
          />
          {{ title }}
        </b-form-group>
        <b-button
            size="sm"
            variant="outline-secondary"
            class="mr-1"
            @click="onClose"
        >
          取消
        </b-button>
        <b-button
            size="sm"
            variant="primary"
            @click="onConfirm"
        >
          确认
        </b-button>
      </span>
    </b-popover>
    <span :id="uuid" @click.stop="onToggle">
      <slot></slot>
    </span>
  </span>

</template>

<script>
import { reactive, toRefs } from '@vue/composition-api'
import { v4 as uuid } from 'uuid'
export default {
  name: 'XyPopConfirm',
  props: {
    title: {
      type: String,
      default: '你确定要执行此操作吗？'
    },
  },
  setup(props, { emit }) {

    const state = reactive({
      popoverShow: false
    })
    const methods = {
      onClose() {
        state.popoverShow = false
      },
      onConfirm() {
        emit('confirm')
        state.popoverShow = false
      },
      onToggle() {
        state.popoverShow = !state.popoverShow
      }
    }
    return {
      ...toRefs(state),
      ...methods,
      uuid:uuid()
    }
  },
}
</script>

<style scoped>

</style>
